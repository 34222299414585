import { HTTP } from '../common/http-common'
const resource = process.env.VUE_APP_BASE_URI + '/api/search';
export default {    
    search(industryId, regionId) {
      return HTTP
        .get(`${resource}/${industryId}/${regionId}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    }
};