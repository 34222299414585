<template>
  <div class="container rounded-3">
    <div class="row mb-3">      
      <div class="col-2 fw-bold">
        Name: 
      </div>
      <div class="col-10">
        {{name}}
      </div>
    </div>
    <div class="row mb-3">
       <div class="col-2 fw-bold">
        ABN: 
      </div>
      <div class="col-10">
        {{abn}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2 fw-bold">
        Contact Number: 
      </div>
      <div class="col-10">
        {{contactNumber}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2 fw-bold">
        Description: 
      </div>
      <div class="col-10">
        {{ description }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-success" @click="hireBusiness">Hire!</button>
      </div>
      <div class="col">
        <button type="button" @click="$router.push('/')" class="btn btn-secondary">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import business_api from "../../api/business_api";
import job_api from "../../api/job_api";

export default {
  name: "BusinessShowcase",
  data: function () {
    return {
      name: "",
      abn: "",
      cescription: "",
      contactNumber: "-------"
    };
  },
  methods: {
    hireBusiness() {
      var self = this;
      job_api.hire(this.businessId).then((data) => {
        self.contactNumber = data;
      });
    },    
  },
  created() {    
    var self = this;
    business_api.getBusinessShowcase(this.$route.params.id).then((data) => {
      self.name = data.name;
      self.description = data.description;
      self.abn = data.abn;
    });
    this.businessId = this.$route.params.id; 
  },
};
</script>