<template>
<Suspense>
  <template #default>
  <div class="container">
    <div class="row mb-3" v-for="business in businesses" :key="business.id">
      <div class="col"> 
        {{business.name}}     
      </div>
      <div class="col"> 
       {{business.description}}          
      </div>
      <div class="col"> 
        {{business.abn}}           
      </div>   
      <div class="col"> 
        <button type="button" @click="edit(business.id)" class="btn btn-warning me-1">Edit</button>
        <button type="button" @click="deleteBusiness(business.id)" class="btn btn-danger">Delete</button>      
      </div>
    </div>
  </div>
  </template>
  <template #fallback> Businesses loading... </template>
  </Suspense>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const businesses = computed(() => store.state.business.businesses);
    
    const removeBusiness = (business) => store.dispatch('business/removeBusiness', business)

    store.dispatch('business/getBusinesses')

    return {
      businesses,
      removeBusiness
    }
  },
  methods: {
    edit(id) {
      this.$router.push(`/business/${id}`);
    },
    deleteBusiness(id){
      if(confirm("Are you sure?"))
      {
        this.removeBusiness(id);
      }
    }
  }
};
</script>