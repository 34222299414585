import { HTTP } from '../common/http-common'
const resource = process.env.VUE_APP_BASE_URI + '/api/Business/';
export default {
    addBusiness(businessJson) {
      return HTTP
        .post(
          resource,
          businessJson
        )
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    removeBusiness(businessId) {
      return HTTP
        .delete(
          `${resource}${businessId}`,
        )
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    getBusiness(businessId) {
      return HTTP
        .get(`${resource}${businessId}`)
        .then((response) => {
          return response.data;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    getBusinessShowcase(businessId) {
      return HTTP
        .get(`${resource}${businessId}/showcase`)
        .then((response) => {
          return response.data;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    getBusinesses() {
      return HTTP
        .get(`${resource}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    updateBusiness(businessId, businessJson) {
      return HTTP
        .put(`${resource}${businessId}`, businessJson)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    }
};