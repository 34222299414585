import axios from 'axios';
import {authService} from './../services/auth.service'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BUSINESS_URI,
})

HTTP.defaults.headers.post['Content-Type'] = 'application/json';
// Add a request interceptor
HTTP.interceptors.request.use(
  config => {    
    return authService.getToken().then((accessToken) =>{
      if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken.accessToken;
      }
  
      return config;
    });
    
  },
  error => {
    Promise.reject(error)
  });

  export default HTTP