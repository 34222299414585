import { createRouter, createWebHistory  } from "vue-router";
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import Business from '../components/Business/Business'
import BusinessManagement from '../components/BusinessManagement'
import BusinessShowcase from '../components/Job/BusinessShowcase'
import { authService } from "../services/auth.service";

const routes = [
  { path: '/login', 
    component: Login,    
    meta: {
      public: true,  // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    } 
  },
  { path: '/', component: Dashboard },
  { path: '/business/:id?', component: Business },
  { path: '/businessmanagement', component: BusinessManagement },
  { path: '/businessShowcase/:id', component: BusinessShowcase }
]

const router = new createRouter({
  history: createWebHistory(__dirname),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const loggedIn = authService.getUser()//true; //!!TokenService.getToken();

  if(to.hash)
    return;
  
  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }

  next();

  // if(to.matched.some(record => record.meta.requiresAuth)) {
  //   if (store.getters.isLoggedIn) {
  //     next()
  //     return
  //   }
  //   next('/login') 
  // } else {
  //   next() 
  // }
})

// router.beforeEach((to, from, next) => {
//   const isPublic = to.matched.some(record => record.meta.public)
//   const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
//   const loggedIn = !!TokenService.getToken();

//   if (!isPublic && !loggedIn) {
//     return next({
//       path:'/login',
//       query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
//     });
//   }

//   // Do not allow user to visit login page or register page if they are logged in
//   if (loggedIn && onlyWhenLoggedOut) {
//     return next('/')
//   }

//   next();
// })

export default router