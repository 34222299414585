<template>
  <div class="container rounded-3">
    <form @submit="onSubmit">
      <div class="row mb-3 me-1 ms-1 justify-content-end">
        <div class="col-2 sm-2">
          <button class="btn btn-link" @click="logout">logout</button>
        </div>
        <div class="col-1">
          <span
            style="cursor: pointer; font-size: 25px"
            @click="$router.push('/BusinessManagement')"
            ><i class="fas fa-user"></i
          ></span>
        </div>
      </div>
      <div class="row mb-3 me-1 ms-1">
        <h1 class="mb-5" v-if="user != null">
          Welcome to Plugsi, {{ user.idToken.name }}
        </h1>
      </div>
      <div class="row mb-3 me-1 ms-1">
        <label for="ddlIndustry" class="col-sm-2 col-form-label">I need </label>
        <div class="col sm-10">
          <VueMultiselect
            id="ddlIndustry"
            v-model="industry"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            label="name"
            :options="industries"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :close-on-select="true"
            :options-limit="300"
            :preserveSearch="true"
            :limit-text="limitText"
            :show-labels="false"
            :max-height="600"
            :show-no-results="true"
            @search-change="asyncFindIndustry"
          >
            <span slot="noResult">
              Oops! No elements found. Consider changing the search query.
            </span>
          </VueMultiselect>
        </div>
      </div>
      <div class="row mb-3 me-1 ms-1" v-if="industry">
        <label for="ddlRegions" class="col-sm-2 col-form-label">in</label>
        <div class="col sm-10">
          <VueMultiselect
            id="ddlRegions"
            v-model="region"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            label="name"
            :options="regions"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :limit-text="limitText"
            :max-height="600"
            :show-no-results="true"
            @search-change="asyncFindRegion"
          >
            <span slot="noResult">
              Oops! No elements found. Consider changing the search query.
            </span>
          </VueMultiselect>
        </div>
      </div>

      <div v-for="business in businesses" :key="business.businessId" class="card mb-3 w-50 mx-auto" style="cursor: pointer" @click="$router.push('/businessShowcase/'+ business.businessId)">
        <div class="row g-0">
          <div class="col-md-2">
            <img src="@/assets/business_1.png" class="card-img-top" alt="..." />
          </div>
          <div class="col-md-10">
            <div class="card-body">
              <h5 class="card-title">{{ business.businessName }}</h5>
              <p class="card-text">
                {{business.businessDescription}}
              </p>
              <p v-if="business.starRating > 0" class="card-text"><star-rating :inline="true" :rating="business.starRating" :increment="0.5" :read-only="true" :show-rating="false"></star-rating></p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="noresults">No businesses to recommend :(</div>
      <div class="row mb-3 me-1 ms-1 justify-content-md-center" v-if="showSearch">
        <div class="col-2">
          <button type="submit" class="btn btn-success">Search</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { authService } from "./../services/auth.service";
import StarRating from 'vue-star-rating'
import VueMultiselect from "vue-multiselect";
import lookup_api from "../api/lookup_api";
import search_api from "../api/search_api";

export default {
  name: "Dashboard",
  components: {
    VueMultiselect,
    StarRating
  },
  data: function () {
    return {
      industry: "",
      region: "",
      industries: [],
      regions: [],
      businesses: [],
      isLoading: false,
      noresults: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      var self = this;
      search_api.search(this.industry.id, this.region.id).then((data) => {
        self.businesses = data;
        if (!data || data.length < 1) {
          self.noresults = true;
        } else {
          self.noresults = false;
        }
      });
    },
    logout() {
      authService.logout();
    },
    limitText(count) {
      return `and ${count} other countries`;
    },
    asyncFindRegion(query) {
      this.isLoading = true;
      var self = this;
      lookup_api
        .getLookups("region", { startsWith: query })
        .then((response) => {
          self.regions = response.data.map((region) => {
            return { id: region.id, name: region.name };
          });
          self.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    asyncFindIndustry(query) {
      this.isLoading = true;
      var self = this;
      lookup_api
        .getLookups("industry", { startsWith: query })
        .then((response) => {
          self.industries = response.data.map((industry) => {
            return { id: industry.id, name: industry.name };
          });
          self.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearAll() {
      this.businessIndustry.regions = [];
    },
  },
  computed: {
    user: function () {
      return authService.getUser();
    },
    showSearch: function () {
      return this.industry && this.regions.length > 0;
    },
  },
};
</script>