import { HTTP } from '../common/http-common'
const resource = process.env.VUE_APP_BASE_URI + '/api/Lookup/';
export default {
    addLookup(LookupJson) {
      return HTTP
        .post(
          resource,
          LookupJson
        )
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    removeLookup(lookupId) {
      return HTTP
        .delete(
          `${resource}${lookupId}`,
        )
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    getLookups(category, startsWith) {
      return HTTP
        .post(
          `${resource}${category}`,
          startsWith
        )
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    getLookupsByIds(category, ids) {
      return HTTP
        .post(
          `${resource}${category}/byids`,
          ids
        ).then((response) => {
          return response.data;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    }
};