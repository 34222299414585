<template>
  <div>
    <h1 class="mb-5">Business Directory</h1>

    <businesses />
    
    <router-link to="/business">Add business</router-link>
    <br />
    <br />
    
    <button type="button" @click="$router.push('/')" class="btn btn-secondary">
      Cancel
    </button>
  </div>
</template>

<script>
import Businesses from "./Business/Businesses.vue";
import { authService } from '../services/auth.service' 

export default {
  components: {
    'businesses': Businesses
  },
  name: "Dashboard",
  methods: {
    logout() {
      authService.logout();
    }
  },
  computed: {
    user: function() {
      return authService.getUser();
    }
  }
};
</script>