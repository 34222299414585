<template>
  <div>
    <div class="card mb-3">
      <button
        type="button"
        class="btn btn-close mb-2"
        style="color: grey; align-self: flex-end"
        @click="removeBusinessIndustry"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row mb-3 me-1 ms-1">
        <label for="ddlIndustry" class="col-sm-2 col-form-label">Select Industry</label>
        <div class="col sm-10">
          <VueMultiselect
            id="ddlIndustry"
            v-model="businessIndustry.industry"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            label="name"
            :options="industries"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :close-on-select="true"
            :options-limit="300"
            :preserveSearch="true"
            :limit-text="limitText"
            :show-labels="false"
            :max-height="600"
            :show-no-results="true"
            @search-change="asyncFindIndustry"
          >
            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </VueMultiselect>
        </div>
      </div>
      <div class="row mb-3 me-1 ms-1">
        <label for="ddlRegions" class="col-sm-2 col-form-label">Select Regions</label>
        <div class="col sm-10">
          <VueMultiselect
            id="ddlRegions"
            v-model="businessIndustry.regions"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            label="name"
            :options="regions"
            :multiple="true"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="300"
            :limit="3"
            :limit-text="limitText"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="asyncFindRegion"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
                <span>{{ option.name }}</span>
                <span class="custom__remove" @click="remove(option)">❌</span>
              </span>
            </template            >
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="selectedRegions.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </VueMultiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import lookup_api from "../../api/lookup_api";

export default {
  name: "BusinessIndustry",
  props: {
    businessIndustry: Object,
  },
  components: {
    VueMultiselect,
  },
  data: function () {
    return {
      industries: [],
      regions: [],
      isLoading: false
    };
  },
  methods: {
    limitText(count) {
      return `and ${count} other countries`;
    },
    asyncFindRegion(query) {
      this.isLoading = true;
      var self = this;
      lookup_api
        .getLookups("region", { startsWith: query })
        .then((response) => {
          self.regions = response.data.map(region => {return { id: region.id, name: region.name }});
          self.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    asyncFindIndustry(query) {
      this.isLoading = true;
      var self = this;
      lookup_api
        .getLookups("industry", { startsWith: query })
        .then((response) => {
          self.industries = response.data.map(industry => { return { id: industry.id, name: industry.name }});
          self.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearAll() {
      this.businessIndustry.regions = [];
    },
    removeBusinessIndustry() {
      this.$emit("remove-business-industry", this.businessIndustry.industryId);
    },
  },
  // created() {
  //   this.asyncFindIndustry(this.businessIndustry.industryId.split(':')[1]);
  // }
};
</script>
<style>
.multiselect__clear {
  position: absolute;
  right: 41px;
  height: 40px;
  width: 40px;
  display: block;
  cursor: pointer;
  z-index: 2;
}
.multiselect__clear:after,
.multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
}
.multiselect__clear:before {
  transform: rotate(45deg);
}
.multiselect__clear:after {
  transform: rotate(-45deg);
}
.card {
  background-color: transparent !important;
}
</style>
