import * as Msal from 'msal'
import config from '../config'

class AuthService {
  constructor() {
    let PROD_REDIRECT_URI = 'https://plugsi.com/login';
    let redirectUri = window.location.origin;
    if (window.location.hostname !== 'localhost') {
      redirectUri = PROD_REDIRECT_URI;
    }
    this.applicationConfig =
    {
      auth: {
        clientId: config.clientid, //This is your client ID
        authority: config.authority, //This is your tenant info
        validateAuthority: false,
        redirectUri: redirectUri
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
      },
      apiScopes: config.b2cScopes
    }
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig)
  }

  login() {
    return this.app.loginPopup({ scopes: this.applicationConfig.apiScopes });
  }

  logout() {
    this.app.logout()
  }

  resetPassword() {
    this.app.authority = config.reset;
    return this.login().then(() => {
      this.app.authority = config.authority;
    });
  }

  getUser() {
    var user = this.app.getAccount()
    // if (user == null)
    //   this.logout();

    return user;
  }

  getToken() {
    return this.app.acquireTokenSilent({ scopes: this.applicationConfig.apiScopes }).catch((error) => {
      // eslint-disable-next-line
      console.log("silent token acquisition fails. acquiring token using popup:" + error);
      // fallback to interaction when silent call fails
      return this.app.acquireTokenPopup({ scopes: this.applicationConfig.apiScopes }).catch(error => {
        // TODO: Need to redirect the user back to the login page as something catastrophic has occurred
        // eslint-disable-next-line
        console.log(error);
      });
    })
  }
}

export const authService = new AuthService();