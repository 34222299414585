import { authService } from '../../services/auth.service'
import business_api from '../../api/business_api'

const state = {
  status: '',
  user: {},
  businesses: []
}

const getters = {
  authStatus: state => state.status,
  businesses: state => state.businesses
}

const actions = {
  getBusinesses({ commit }) {
    return business_api.getBusinesses().then((data) => {
      commit('getBusinesses', data);
    })
  },
  login({ commit, reject }) {
    commit('auth_request')
    return authService.login().then(() => {
      commit('auth_success')
    }).catch(err => {
      commit('auth_error')
      localStorage.removeItem('token')
      reject(err)
    });
  },
  logout({ commit, reject }) {
    commit('logout')
    return authService.logout().catch(err => {
      commit('auth_error')
      localStorage.removeItem('token')
      reject(err)
    });
  },
  removeBusiness({ commit }, id) 
  {
    return business_api.removeBusiness(id).then(() => {
      commit('removeBusiness', id)
    })
  }
}

const mutations = {
  auth_request(state) {
    state.status = 'loading'
  },
  auth_success(state) {
    state.status = 'success'
  },
  auth_error(state) {
    state.status = 'error'
  },
  logout(state) {
    state.status = ''
  },
  getBusinesses(state, businessData) {
    state.businesses = businessData;
  },
  removeBusiness(state, id){
    var businessIndex = state.businesses.findIndex(business => business.id === id)
    state.businesses.splice(businessIndex, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}