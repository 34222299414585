<template>
  <div class="container w-50">
    <form @submit="onSubmit" @reset="onReset" v-if="show">
      <div class="row mb-3">
        <label for="tbBusinessName" class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-10">
          <input
            id="tbBusinessName"
            v-model="form.name"
            type="text"
            required
            placeholder="My Business"
            class="form-control"
          />
        </div>
      </div>      
      <div class="row mb-3">
        <label for="tbAbn" class="col-sm-2 col-form-label">ABN</label>
        <div class="col">
          <input
            id="tbAbn"
            v-model="form.abn"
            required
            placeholder="123245678900"
            description="The ABN number for your business"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label for="tbContactNumber" class="col-sm-2 col-form-label">Contact Number</label>
        <div class="col">
          <input
            id="tbContactNumber"
            v-model="form.contactNumber"
            required
            placeholder="0411111111"
            description="The contact number for your business"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label for="tbBusinessDescription" class="col-sm-2 col-form-label"
          >Description</label
        >
        <div class="col-sm-10">
          <textarea
            rows=3
            id="tbBusinessDescription"
            v-model="form.description"
            required
            placeholder="Family owned australian goodness"
            description="A description of your business"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <button type="button" @click="addIndustry" class="btn btn-secondary">
            Add Industry
          </button>
        </div>
      </div>
      <div
        class="row"
        v-for="industry in form.businessIndustries"
        :key="industry.industryId"
      >
        <div class="col">
          <BusinessIndustry
            :businessIndustry="industry"
            v-on:remove-business-industry="removeIndustry"
          ></BusinessIndustry>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-success">
            {{ this.buttonText }}
          </button>
        </div>
        <div class="col">
          <button type="reset" class="btn btn-danger">Clear</button>
        </div>
        <div class="col">
          <button
            type="button"
            @click="$router.push('/')"
            class="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BusinessIndustry from "./BusinessIndustry";
import business_api from "../../api/business_api";

export default {
  name: "Business",
  components: {
    BusinessIndustry,
  },
  data: function () {
    return {
      buttonText: "Add",
      businessId: null,
      form: {
        abn: "",
        businessIndustries: [],
        contactNumber: "",
        description: "",
        name: "",
      },
      industryCount: 0,
      show: true,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let business = {
        name: this.form.name,
        contactNumber: this.form.contactNumber,
        description: this.form.description,
        abn: this.form.abn,
        businessIndustries: [],
      };
      this.form.businessIndustries.forEach((industry) => {
        business.businessIndustries.push({
          industryId: industry.industry.id,
          regions: industry.regions.map((region) => region.id),
        });
      });
      let businessPromise;
      if (this.businessId) {
        businessPromise = business_api.updateBusiness(
          this.businessId,
          business
        );
      } else {
        businessPromise = business_api.addBusiness(business);
      }
      businessPromise.then(() => {
        this.$router.push("/");
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.name = "";
      this.form.description = "";
      this.form.abn = "";
      this.form.contactNumber = "";
      this.form.businessIndustries = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    addIndustry() {
      this.form.businessIndustries.push({
        industry: {
          industryId: "industry" + this.industryCount,
          name: "new industry",
        },
        regions: [],
      });
      this.industryCount++;
    },
    removeIndustry(id) {
      var industryIndex = this.form.businessIndustries.findIndex(
        (industry) => industry.industryId == id
      );
      this.form.businessIndustries.splice(industryIndex, 1);
      this.industryCount--;
    },
  },
  created() {
    if (this.$route.params.id) {
      var self = this;
      business_api.getBusiness(this.$route.params.id).then((data) => {
        self.form.name = data.name;
        self.form.description = data.description;
        self.form.abn = data.abn;
        self.form.contactNumber = data.contactNumber;
        self.form.businessIndustries = data.businessIndustries.map(
          (businessIndustry) => {
            return {
              industry: {
                id: businessIndustry.industryId,
                name: businessIndustry.industryId.split(":")[1],
              },
              regions: businessIndustry.regions.map(region => {
                return{
                  id: region,
                  name: region.split(':')[1]
                }
              })
            };
          }
        );
      });
      this.businessId = this.$route.params.id;
      this.buttonText = "Save";
    }
  },
};
</script>
<style>
.multiselect__clear {
  position: absolute;
  right: 41px;
  height: 40px;
  width: 40px;
  display: block;
  cursor: pointer;
  z-index: 2;
}
.multiselect__clear:after,
.multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
}
.multiselect__clear:before {
  transform: rotate(45deg);
}
.multiselect__clear:after {
  transform: rotate(-45deg);
}
</style>
