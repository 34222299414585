<template>
  <div class="container login">
    <div class="d-flex justify-content-center h-75">
      <div class="card">
        <div class="card-header">
          <h3>Welcome to Plugsi</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <button class="btn login_btn" @click="login">Login</button>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-center">
            <button class="btn btn-link" @click="resetPassword">Forgot your password?</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authService } from './../services/auth.service'

export default {
  name: "Login",
  methods: {
    login() {
      var self = this;

      authService.login().then(() => {
        self.$router.push("/")
      }).catch((error) => {
        const errorDesc = error.errorMessage;
        if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
            authService.resetPassword();
        }
      });
    },
    resetPassword() {
      authService.resetPassword();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  align-content: center;
  background-color: transparent !important;
}

.login .card {
  height: 220px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #f9dd00;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #f9dd00;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #f9dd00 !important;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}
</style>
