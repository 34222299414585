<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { HTTP } from "./common/http-common";
export default {
  name: "app",
  created: function () {
    var self = this;
    HTTP.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          self.$store.dispatch("logout");
        }
        throw err;
      });
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
@import url("https://fonts.googleapis.com/css?family=Numans");

.card {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#app {
  font-family: "Numans", sans-serif;
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.container{
  background-color: #fceb67;
  padding:15px;
}
</style>
