import { HTTP } from '../common/http-common'
const resource = process.env.VUE_APP_BASE_URI + '/api/Job';
export default {    
    getJobs(industryId, regionId) {
      return HTTP
        .get(`${resource}/${industryId}/${regionId}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    },
    hire(businessId) {
      return HTTP
        .post(`${resource}/${businessId}`, {})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          return null;
        });
    }
};